@import "../../config/styles/_all";

.slide {
	width: calc(100%) !important;
	display: flex !important;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
	text-align: left;
	margin-bottom: 2rem;
	@include respond-below("sm") {
		flex-direction: row;
	}

	&:focus {
		outline: none;
	}

	.avatar {
		flex: 1 1 20%;

		@include respond-below("sm") {
			display: none;
		}

		img {
			border-radius: 50%;
			width: 110px;
			margin-top: 2rem;
		}
	}

	.content {
		flex: 1 1 80%;
		padding: 1rem;
		position: relative;
		@include respond-below("sm") {
			flex: 1 1 100%;
			padding: 0;
			margin-top: 5em;
		}
	}
}

.quotes {
	width: 75px;
	stroke: _palette(accent1, bg);
	position: fixed;
	z-index: -1;
}

.name,
.designation {
	color: _palette(fg-bold);
	margin: 0;
	font-size: 0.75rem;
}

.name {
	font-size: 1rem;
	line-height: 1.5;
}

.recommendation {
	margin-bottom: 1rem;
	font-style: italic;
	&::before {
		content: "“";
		color: _palette(accent1, bg);
	}

	&::after {
		content: "”";
		color: _palette(accent1, bg);
	}
}
