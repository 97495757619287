@import "../../config/styles/_all";

$link-width: 50px;
$link-height: 50px;

.social {
	margin: 0;
	list-style: none;
	padding: 0;

	li {
		padding: 0;
		padding-bottom: 1rem;
	}
}

.navBar {
	position: fixed;
	top: 0;
	display: flex;
	width: 60px;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
	z-index: 3;
	min-height: 500px;
	background: mix(_palette(bg), black, 75%);
	color: _palette(fg);
}

.nav,
.social {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.link,
.socialLink {
	width: $link-width;
	height: $link-height;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 1rem;
	text-decoration: none;

	&.active {
		border-color: _palette(accent1, bg);
		color: _palette(accent1, bg);

		path,
		circle {
			stroke: _palette(accent1, bg);
		}
	}

	@include vendor("appearance", "none");
	@include vendor(
		"transition",
		(
			"background-color #{_duration(transition)} ease-in-out",
			"color #{_duration(transition)} ease-in-out",
			"border-color #{_duration(transition)} ease-in-out"
		)
	);

	path,
	circle {
		@include vendor("transition", ("stroke #{_duration(transition)} ease-in-out"));
	}

	&:hover,
	&:active {
		border-color: _palette(accent1, bg);
		color: _palette(accent1, bg);

		svg {
			width: calc(#{$link-width} - 65%);
			height: calc(#{$link-height} - 65%);
		}

		path,
		circle {
			stroke: _palette(accent1, bg);
		}

		.linkDesc {
			visibility: visible;
			opacity: 1;
		}
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	&Icon {
		width: calc(#{$link-width} - 25%);
		height: calc(#{$link-height} - 25%);
		@include vendor(
			"transition",
			("height #{_duration(transition)} ease-in-out", "width #{_duration(transition)} ease-in-out")
		);

		path,
		circle {
			stroke: _palette(fg-light);
		}
	}

	&Desc {
		text-transform: uppercase;
		text-decoration: none;
		font-size: 0.5em;
		margin-top: 0.35rem;
		visibility: hidden;
		opacity: 0;
		@include vendor(
			"transition",
			("visibility #{_duration(transition)} ease-in-out", "opacity #{_duration(transition)} ease-in-out")
		);
	}
}

.social {
	&Link {
		width: calc(#{$link-width} - 30%);
		height: calc(#{$link-height} - 30%);
		margin: auto;

		&Icon {
			width: calc(#{$link-width} - 60%);
			height: calc(#{$link-height} - 60%);
		}

		&:hover,
		&:active {
			svg {
				width: calc(#{$link-width} - 60%);
				height: calc(#{$link-height} - 60%);
			}
		}
	}
}
