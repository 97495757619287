@import "../../config/styles/_all";

.about {
	composes: wrapper from "../../pages/home/HomePage.module.scss";
	margin-right: -5vw;
	@include respond-below("md") {
		margin: 0;
	}

	&Wrapper {
		display: flex;
		align-items: flex-start;
		@include respond-below("md") {
			flex-direction: column;
			align-items: center;
		}
	}
}

.profileContainer {
	text-align: center;
}

.profileHeader {
	margin-bottom: 0;
}

.profileSubheader {
	margin-top: 0;
	color: rgba(247, 241, 255, 0.5);
}

.avatar {
	border-radius: 50%;
	width: 200px;
}

.aside {
	&Container {
		text-align: center;
		padding-right: 2rem;
		@include respond-below("md") {
			margin-bottom: 2rem;
		}
	}
}

.information {
	margin-bottom: 2rem;
}

.content {
	max-height: 80vh;
	padding: 0 1rem;

	b {
		color: _palette(fg-light);
	}
}
