@import "../../config/styles/_all";

.wrapper {
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90vw;
	margin: 0 auto;
	@include respond-below("sm") {
		flex-direction: column;
		width: 100%;
		align-items: flex-start;
		padding-top: 5vh;
	}
}

.recommendations {
	padding: 1rem;
	width: calc(100% - 50vw);
	@include respond-between("sm", "md") {
		display: none;
	}
	@include respond-below("sm") {
		display: block;
		width: calc(100%);
		padding: 0;
	}
}

.who {
	padding: 1rem;
	font-family: "Fira Code", monospace;
	font-feature-settings: "calt" 1; /* Enable ligatures for IE 10+, Edge */
	text-rendering: optimizeLegibility; /* Force ligatures for Webkit, Blink, Gecko */
	display: block;

	@include respond-below("sm") {
		padding: 0;
	}

	.console {
		overflow: hidden; /* Ensures the content is not revealed until the animation */
		border-right: 0.75rem solid _palette(accent1, bg); /* The typwriter cursor */
		padding-right: 0.25rem;
		white-space: nowrap; /* Keeps the content on a single line */
		margin: 0 auto 0 0; /* Gives that scrolling effect as the typing happens */
		letter-spacing: 0.2rem; /* Adjust as needed */
		font-size: 2.5rem;
		width: 35rem;

		@include respond-below("sm") {
			font-size: 1.4rem;
			width: 22rem;
		}

		animation-duration: 3s;
		animation-timing-function: steps(44);
		animation-delay: 0;
		animation-fill-mode: forwards;
		animation-play-state: running;
		animation-name: typingConsole;

		&::before {
			content: "-> ~";
			margin-right: 1rem;
			color: _palette(accent1, bg);
			font-weight: bold;
		}
	}

	.result {
		margin-bottom: 4em;
		padding-top: 1em;

		.line1,
		.line2,
		.line3 {
			overflow: hidden; /* Ensures the content is not revealed until the animation */
			border-right: 0.75em solid _palette(accent1, bg); /* The typwriter cursor */
			white-space: nowrap; /* Keeps the content on a single line */
			margin: 0.25em auto 0.5em 0.75em; /* Gives that scrolling effect as the typing happens */
			letter-spacing: 0.2rem; /* Adjust as needed */
			font-size: 1.5rem;
			opacity: 0;
			@include respond-below("sm") {
				font-size: 1.15rem;
			}
		}

		.line1 {
			width: 14rem;
			animation-duration: 2s;
			animation-timing-function: steps(40);
			animation-delay: 4s;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
			animation-play-state: running;
			animation-name: typingLine1;
			@include respond-below("sm") {
				width: 12rem;
			}
		}

		.line2 {
			width: 12rem;
			animation-duration: 2s;
			animation-timing-function: steps(40);
			animation-delay: 6s;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
			animation-play-state: running;
			animation-name: typingLine2;
			@include respond-below("sm") {
				width: 10rem;
			}
		}

		.line3 {
			width: 24.5rem;
			animation-duration: 6s, 1s;
			animation-timing-function: steps(56), step-end;
			animation-delay: 8s, 14s;
			animation-iteration-count: 1, infinite;
			animation-fill-mode: forwards;
			animation-play-state: running;
			animation-name: typingLine3, blinkTextCursor;
			@include respond-below("sm") {
				width: 19.5rem;
			}
		}

		.highlight {
			background: linear-gradient(90deg, transparent 50%, _palette(accent1, bg) 0);
			background-size: 210% 100%;
			margin: -0.25em 0.25em;
			padding: 0.25em 0.5em;
			border-right: none;
			animation-name: typingLine3, highlightText;
			animation-duration: 6s, 1s;
			animation-timing-function: steps(56), ease;
			animation-delay: 0s, 0s;
			animation-iteration-count: 1, 1;
			animation-fill-mode: forwards;
			animation-play-state: running;
		}
	}
}

@keyframes typingConsole {
	0% {
		width: 0;
	}
	99.9% {
		border-right: 0.75rem solid _palette(accent1, bg); /* The typwriter cursor */
	}
	to {
		border: none;
	}
}

@keyframes typingLine1 {
	0% {
		width: 0;
	}
	1% {
		opacity: 1;
	}
	99.9% {
		border-right: 0.75rem solid _palette(accent1, bg); /* The typwriter cursor */
	}
	100% {
		opacity: 1;
		border: none;
	}
}

@keyframes typingLine2 {
	0% {
		width: 0;
	}
	1% {
		opacity: 1;
	}
	99.9% {
		border-right: 0.75rem solid _palette(accent1, bg); /* The typwriter cursor */
	}
	100% {
		opacity: 1;
		border: none;
	}
}

@keyframes typingLine3 {
	0% {
		width: 0;
	}
	1% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@keyframes blinkTextCursor {
	from,
	to {
		border-color: _palette(accent1, bg);
	}

	50% {
		border-color: transparent;
	}
}

@keyframes highlightText {
	from {
		background-position: 0 100%;
	}
	to {
		background-position: 100% 100%;
	}
}
