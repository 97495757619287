@import "../../config/styles/_all";

.wrapper {
	composes: wrapper from "../../pages/home/HomePage.module.scss";
}

.page {
	width: 100%;
	display: flex;
}

.content {
	width: calc(100% - 40vw);
	padding: 0 1rem;
	@include respond-below("md") {
		width: calc(100%);
	}
}

.form {
	text-align: right;
	@include respond-below("md") {
		text-align: left;
	}

	.row {
		display: flex;
		@include respond-below("md") {
			flex-direction: column;
		}
	}
}

.animation {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 50vw;
	position: relative;

	svg {
		width: 70%;
	}

	@include respond-below("md") {
		display: none;
	}
}

.email {
	margin-bottom: 2rem;
	a,
	span {
		color: _palette(fg-bold);
		text-decoration: none;
	}
}
