@import "../config/styles/_all";

.layout {
	position: relative;
	min-height: 100%;

	.particles {
		z-index: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.content {
	height: 100vh;
	display: flex;
	position: relative;
	z-index: 1;
	width: calc(100% - 60px);
	margin: 0 0 0 auto;
	padding: 0 2rem;

	@include respond-below("sm") {
		padding: 0.75em;
	}
}
